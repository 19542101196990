/** FIXME: https://app.asana.com/0/1206405657480018/1207476676782243/f */
import React, { useState } from "react";
import { Button } from "./../components/ui/button";
import Calculator from "../components/Calculator";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import { isMobileDevice } from "../utils";

interface PricingTierProps {
  name: string;
  price: string;
  index: number;
  selectedIndex: number;
  description: string;
  features: string[];
  setSelectedTier: (index: number) => void;
}

const PricingTier: React.FC<PricingTierProps> = ({
  name,
  price,
  features,
  index,
  selectedIndex,
  description,
  setSelectedTier,
}) => {
  const isSelected = selectedIndex === index;

  return (
    <div
      className={`h-124 cursor-pointer relative border border-gray w-72 h-full p-8 flex flex-col bg-black rounded-2xl flex-grow flex-shrink-0 flex-basis-0`}
      onClick={() => setSelectedTier(index)}
    >
      <h1
        className={`font-plus-jakarta-sans text-2xl ${
          isSelected ? "text-helpar__green" : "text-helpar__blue"
        }`}
      >
        {name}
      </h1>
      <h1 className={`font-plus-jakarta-sans text-white text-5xl`}>{price}</h1>
      <div className="w-full h-56">
        <p className={`font-plus-jakarta-sans text-gray pt-6 text-lg`}>{description}</p>
      </div>
      <Button
        className={`w-56 rounded-full h-12 $ text-${
          isSelected
          ? "black bg-gradient-to-r from-[#95A4FC] to-[#B1FA63]"
          : "black bg-helpar__blue"
        } text-lg`}
        onClick={() => {
          window.open("https://calendly.com/ish_agrawal/30min", "_blank");
        }}
      >
        Get Started
      </Button>
    </div>
  );
};

const Pricing: React.FC = () => {
  const [selectedTier, setSelectedTier] = useState<number>(1);
  const isMobile = isMobileDevice();

  return (
    <div className={`viewarea bg-black`}>
      <TopNavigation />
      <div className="overflow-y-auto h-screen ">
        <div className={`w-full ${isMobile ? "p-8" : "p-16"} `}>
          <h1
            className={`text-white text-${
              isMobile ? "7" : "13"
            }xl font-bold font-plus-jakarta-sans`}
          >
            Pricing
          </h1>
          <p className={`text-white font-plus-jakarta-sans`}>
            A package for every need. Feel free to chat with us to understand
            which might work best for you!
          </p>
          <div className="w-full flex mt-8 gap-4 items-center flex-wrap">
            <PricingTier
              selectedIndex={selectedTier}
              setSelectedTier={setSelectedTier}
              index={0}
              name="Engage"
              price="$399/mo"
              description="Begin your omnichannel journey with the Engage tier. Ideal for those starting out, it provides foundational tools to establish meaningful connections across channels."
              features={[]}
            />
            <PricingTier
              selectedIndex={selectedTier}
              setSelectedTier={setSelectedTier}
              index={1}
              name="Growth"
              price="Let's Chat"
              description="Elevate your omnichannel presence with the Enhance tier. Designed for expansion, it offers advanced features to amplify engagement and streamline operations."
              features={[]}
            />
            <PricingTier
              selectedIndex={selectedTier}
              setSelectedTier={setSelectedTier}
              index={2}
              name="Expansion"
              price="Let's Chat"
              description="Achieve unparalleled success with the Excel tier. Tailored for growth, it delivers cutting-edge solutions to maximize efficiency and innovation."
              features={[]}
            />
            <PricingTier
              selectedIndex={selectedTier}
              setSelectedTier={setSelectedTier}
              index={3}
              name="Hyper Growth"
              price="Let's Chat"
              description="Unleash the full potential of omnichannel mastery with the Enterprise tier. Crafted for industry leaders, it offers bespoke solutions and enterprise-grade features for market dominance."
              features={[]}
            />
          </div>
        </div>
        <div className={`w-full ${isMobile ? "pl-8 pr-8" : "pl-16 pr-16"}  mt-24 flex ${isMobile ? "flex-col" : "flex"}`}>
          <div
            className={`w-${
              isMobile ? "full" : "1/2"
            } justify-center items-center flex`}
          >
            <h1
              className={`font-plus-jakarta-sans text-${
                isMobile ? "5xl" : "10xl"
              } text-left font-bold text-white`}
            >
              Calculate Your Untapped LTV
            </h1>
          </div>
          <div
            className={`w-${
              isMobile ? "full" : "1/2"
            } flex items-center justify-center pt-4`}
          >
            <p className={`font-plus-jakarta-sans text-lightgray text-xl`}>
              Our bet is that you’re leaving money on the table. Let’s find out
              just how much. <br />
              <br />
              Our quick calculator helps you do some back-of-the-napkin math to
              uncover hidden revenue opportunities by understanding the impact
              of unowned retail channels on your LTV. <br />
              <br />
              And here’s the best part — even if you’re not selling through
              unowned channels, HelpAR can still supercharge your customer
              loyalty and retention. We’ve seen it work wonders for our clients.
              Don’t hesitate to reach out and learn how HelpAR can maximize your
              customer value.
            </p>
          </div>
        </div>
        <Calculator />
        <Footer />
      </div>
    </div>
  );
};

export default Pricing;
