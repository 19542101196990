/** FIXME: https://app.asana.com/0/1206405657480018/1207476676782243/f */
import React, { useState, useRef, useEffect } from "react";
import Footer from "../../components/Footer";
import TopNavigation from "../../components/TopNavigation";
import { Button } from "../../components/ui/button";

import "./../../styles/animation.css";

const Index: React.FC = () => {
  const unlockValueCardContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const blurContainerRef = useRef<HTMLDivElement | null>(null);
  const [blurAmount, setBlurAmount] = useState(90);
  const [inView, setInView] = useState(false);
  const [
    connectWithCustomersHeaderVisible,
    setConnectWithCustomersHeaderVisible,
  ] = useState(false);
  const connectWithCustomersHeaderRef = useRef<HTMLHeadingElement | null>(null);
  const lessThanOptimalExperienceRef = useRef<HTMLHeadingElement | null>(null);
  const [
    lessThanOptimalExperienceVisible,
    setLessThanOptimalExperienceVisible,
  ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [textInView, setTextInView] = useState(false);
  const [
    unlockValueCardContainerRefIsScrollable,
    setUnlockValueCardContainerRefIsScrollable,
  ] = useState(false);

  const sectionRef = useRef(null);

  
  
  
  useEffect(() => {
    const words = document.querySelectorAll(".word");
    words.forEach((word: any, index) => {
      word.style.animationDelay = `${index * 0.2}s`;
    });
  }, []);

  useEffect(() => {
    const element = lessThanOptimalExperienceRef.current;
    if (!element) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const words = document.querySelectorAll(".falling-word");
            words.forEach((word: any, index) => {
              word.style.animationDelay = `${1.5 + index * 0.3}s`;
              word.classList.add("visible");
            });
            setLessThanOptimalExperienceVisible(true);
            observer.unobserve(element);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const element = connectWithCustomersHeaderRef.current;
    if (!element) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setConnectWithCustomersHeaderVisible(true);
          observer.unobserve(element);
        }
      });
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const element = document.querySelector(".falling-text");

    if (!element) return;

    const handleIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const textContent = element.textContent;
        element.innerHTML = "";

        if (textContent) {
          textContent.split("").forEach((char, index) => {
            const span = document.createElement("span");
            span.textContent = char === " " ? "\u00A0" : char;
            span.style.animationDelay = `${index * 0.1}s`;
            element.appendChild(span);
          });
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".typewriter-text");

    if (!element) return;

    let originalText = element?.textContent;
    let currentText = "";
    let index = 0;

    const handleTypewriterIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const typeCharacter = () => {
          if (!originalText) return;

          if (index < originalText.length) {
            currentText += originalText[index];
            element.textContent = currentText;
            index++;
            setTimeout(typeCharacter, 60);
          }
        };
        typeCharacter();
      }
    };

    const observer = new IntersectionObserver(handleTypewriterIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".blur-text");

    const handleBlurIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setTextInView(true);
      }
    };

    const observer = new IntersectionObserver(handleBlurIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const handleScroll = () => {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (unlockValueCardContainerRef.current) {
        const cardRect =
          unlockValueCardContainerRef.current.getBoundingClientRect();

        const diff = 0.2;

        if (
          cardRect.top >= -diff * viewportHeight &&
          cardRect.bottom <= viewportHeight * (1 + diff)
        ) {
          setInView(true);
        } else {
          setInView(false);
        }

        if (cardRect.top <= 0) {
          setUnlockValueCardContainerRefIsScrollable(true);
        } else {
          setUnlockValueCardContainerRefIsScrollable(false);
        }
      }

      if (blurContainerRef.current) {
        const bgBlueRect = blurContainerRef.current.getBoundingClientRect();
        const maxBlur = 50;
        let blur = 0;
        const diff = 0.4;

        if (bgBlueRect.top >= -diff * viewportHeight) {
          blur = Math.max((bgBlueRect.top / viewportHeight) * maxBlur, 0);
        } else if (bgBlueRect.bottom <= viewportHeight) {
          blur = Math.max(
            (1 - bgBlueRect.bottom / viewportHeight) * maxBlur,
            0,
          );
        }

        setBlurAmount(blur);
      }
    };

    scrollContainer?.addEventListener("scroll", handleScroll);
    scrollContainer?.addEventListener("resize", handleScroll);

    handleScroll();

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
      scrollContainer?.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <div className={`viewarea bg-black`}>
      <TopNavigation className="absolute" />
      <div className="overflow-auto h-screen relative" ref={scrollContainerRef}>
        <div className="w-full h-full flex flex-col justify-center items-center relative">
        <img
            src="https://s3.amazonaws.com/helpar.io/assets/img/Commercial+Website.gif"
            alt="banner cover"
            className="w-full h-full object-cover absolute"
          />
        <img
                    src="/logo192-white.png"
                    alt="helpar-logo"
                    className="h-8 w-8 cursor-pointer  mix-blend-difference"
                  />
          <h3 className="text-white font-medium opacity-100 text-3xl text-center mix-blend-difference font-plus-jakarta-sans">
            HelpAR Vision
          </h3>
          <h1 className="text-white font-extrabold opacity-100 text-10xl/[7rem] text-center mix-blend-difference font-plus-jakarta-sans typewriter-text">
          Bring Your Customers Into Focus
          </h1>
          
        </div>
        <div className="w-full h-256 p-12 mt-12">
          <div className="w-full h-full relative flex">
            

            <div className="w-152 ml-48 mt-36 ">
              <img
                src="https://s3.amazonaws.com/helpar.io/assets/img/mockup.png"
                alt="banner cover"
                className=""
              />
            </div>
            <div
              className={`w-3/5 h-72 absolute top-12 right-0   ${
                connectWithCustomersHeaderVisible ? "fade-in" : ""
              }`}
              ref={connectWithCustomersHeaderRef}
            >
              <h1
                className={`font-bold top-12 font-plus-jakarta-sans text-11xl text-white leading-none text-left`}
              >
                Connect With <br></br> Your Customers, <br></br> In Their World
              </h1>
            </div>
            <div className="w-1/3 h-56 absolute top-[580px] right-36 flex z-5 flex-col ">
              <p
                className={`font-plus-jakarta-sans text-3xl p-8 text-white text-left`}
              >
                Your customers are right there, hidden in plain sight. Bring
                them into sharper focus with HelpAR and unlock their true value.
              </p>
              <Button
                className="rounded-full w-48 p-7 text-lg ml-8 font-plus-jakarta-sans bg-gradient-to-r from-[#95A4FC] to-[#B1FA63]"
                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Book A Demo
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`w-full bg-black p-8 pt-24 flex justify-center items-center`}
        >
          <div className="flex flex-col">
          
            <h1 className="font-semibold text-center font-plus-jakarta-sans text-9xl text-gray leading-none">
              Unlock Your Biggest <br></br> Hidden Asset:
            </h1>
            <h1 className="font-bold text-center font-plus-jakarta-sans text-17xl text-white leading-none mt-6 falling-text">
              Post Purchase
            </h1>
          </div>
        </div>
        <div
          className={`w-full bg-black h-screen p-8`}
          ref={blurContainerRef}
          style={{
            filter: `blur(${blurAmount}px)`,
          }}
        >
          <div className="relative  h-full w-full">
          
            <div className="absolute w-3/5 left-20 top-20 z-10">
              <h1 className="font-bold font-plus-jakarta-sans text-11xl text-white leading-none">
                The post purchase customer journey is blurry at best.
              </h1>
            </div>
            <div className=" absolute bottom-14 w-1/2 right-20">
              <p className="text-lightgray font-plus-jakarta-sans text-3xl">
                Wouldn’t it be great to know exactly how your customers are
                using your products? Or why they chose them in the first place?
                And let’s be honest, even figuring out who your customers
                actually are can be a real challenge. Right now, you're piecing
                together fragmented data from multiple channels, and it’s nearly
                impossible to get a clear picture.
              </p>
            </div>
          </div>
        </div>
        <div
          className={`w-full bg-black h-screen p-8`}
          ref={lessThanOptimalExperienceRef}
        >
          <div className="relative  h-full w-full">
          <img
            src="https://s3.amazonaws.com/helpar.io/assets/img/BGlighting.png"
            alt="banner cover"
            className="w-full h-full object-cover blur-[300px] absolute "
          />
            <div className="absolute w-3/5 left-20 top-20">
              <h1
                className={`font-bold font-plus-jakarta-sans text-11xl text-white leading-none fade-in `}
              >
                {"Resulting in a less than optimal experience for your customers."
                  .split(" ")
                  .map((word, index) => (
                    <span
                      key={index}
                      className={`falling-word ${
                        lessThanOptimalExperienceVisible ? "visible" : ""
                      }`}
                      style={{
                        animationDelay: lessThanOptimalExperienceVisible
                          ? `${1.5 + index * 0.3}s`
                          : "0s",
                      }}
                    >
                      {word}
                    </span>
                  ))}
              </h1>
            </div>
            <div className=" absolute bottom-0 w-1/2 right-20">
              <p className="text-lightgray font-plus-jakarta-sans text-3xl">
                Customers face their own set of headaches, constantly jumping
                between different platforms to find product information and
                support. They're overwhelmed by the scattered and fragmented
                process, leaving them feeling disconnected from your brand. They
                want to connect with you, but it’s a frustrating and cumbersome
                journey.
              </p>
            </div>
          </div>
        </div>
        <div className={`w-full bg-black h-screen p-8`}>
          <div className=" h-full w-full flex flex-col items-center justify-center gap-y-12">
            <h1 className="text-center font-bold font-plus-jakarta-sans text-13xl text-white leading-none mt-6 typewriter-text helpar-gradient-text">
              Until Now.
            </h1>
            <div className=" w-3/5 flex justify-center items-center">
              <p className="text-white text-center font-plus-jakarta-sans text-3xl">
                HelpAR cuts through the chaos. For you, it’s like putting on
                goggles that bring into focus a clear view of your customers and
                their behaviors. For your customers, it means a smooth,
                integrated post-purchase experience that makes connecting with
                your brand effortless. With HelpAR, meaningful connections with
                your customers post purchase becomes not just possible, but
                powerfully simple.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mb-12 mt-6">
          <h1 className="font-bold p-12 text-left font-plus-jakarta-sans text-10xl text-white leading-none">
            With A Single Tap, <br></br>HelpAR Blends The…
          </h1>
          <div ref={sectionRef} className="w-full flex flex-grow gap-x-6 mt-12 pl-12 pr-12 overflow-x-scroll	flex-wrap-nowrap" >
            <div
              className="mb-6 rounded-2xl w-160 flex flex-shrink-0 h-144 justify-between bg-helpar__blue"
              style={{ backgroundColor: "" }}
            >
              <div className="w-3/5 h-full p-8 flex flex-col justify-between items-start">
                <h1 className="font-bold font-plus-jakarta-sans text-6xl text-left text-black  leading-none">
                  Power of Data
                </h1>
                <p className="font-plus-jakarta-sans text-lg">
                  Dive deep into rich analytics and customer insights, turning
                  every interaction into actionable intelligence that drives
                  strategic decisions and fosters brand growth.
                </p>
              </div>
              <div className="w-1/2 h-full p-4 flex items-center justify-center">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/1.+Power+of+data.gif"
                  className="h-full rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="mb-6 rounded-2xl w-160 flex flex-shrink-0 h-144 justify-between bg-helpar__green"
              style={{ backgroundColor: "" }}
            >
              <div className="w-3/5 h-full p-8 flex flex-col justify-between items-start">
                <h1 className="font-bold font-plus-jakarta-sans text-6xl text-left text-black leading-none">
                  Engaging, Intuitive UX
                </h1>
                <p className="font-plus-jakarta-sans text-lg">
                  Our user-friendly interface ensures that both your team and
                  your customers enjoy smooth, engaging interactions, making
                  every touchpoint a delight.
                </p>
              </div>
              <div className="w-1/2 h-full p-4 flex items-center justify-center">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/2.+UX.gif"
                  className="h-full rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="mb-6 rounded-2xl w-160 flex flex-shrink-0 h-144 justify-between"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div className="w-3/5 h-full p-8 flex flex-col justify-between items-start">
                <h1 className="font-bold font-plus-jakarta-sans text-6xl text-left text-black leading-none">
                  Simplicity of Templates
                </h1>
                <p className="font-plus-jakarta-sans text-lg">
                  Designed for efficiency and impact, our templatized modules
                  allow you to quickly craft compelling post-purchase
                  experiences that resonate with your customers.
                </p>
              </div>
              <div className="w-1/2 h-full p-4 flex items-center justify-center">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/3.+Template.gif"
                  className="h-full rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="mb-6 rounded-2xl w-160 flex flex-shrink-0 h-144 justify-between bg-helpar__yellow"
              style={{ backgroundColor: "" }}
            >
              <div className="w-3/5 h-full p-8 flex flex-col justify-between items-start">
                <h1 className="font-bold font-plus-jakarta-sans text-6xl text-left text-black leading-none">
                  Intelligence of AI
                </h1>
                <p className="font-plus-jakarta-sans text-lg">
                  Our advanced AI algorithms offer personalized customer
                  interactions and support, ensuring that each customer feels
                  uniquely understood and valued, while providing you with smart
                  insights for continuous improvement.
                </p>
              </div>
              <div className="w-1/2 h-full p-4 flex items-center justify-center">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/4.+AI.gif"
                  className="h-full rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-full h-screen  p-12 flex flex-col items-center ${
            unlockValueCardContainerRefIsScrollable ? "overflow-y-auto" : ""
          }`}
          ref={unlockValueCardContainerRef}
        >
          <div className="w-3/5  mb-12 gap-y-8 flex flex-col">
            <h1 className="font-bold text-center font-plus-jakarta-sans text-10xl text-gray leading-none">
              Let Us Help You.
            </h1>
            <h1
              className={`font-bold text-center font-plus-jakarta-sans text-10xl text-white leading-none`}
            >
              See The Unseen.
            </h1>
          </div>
          <div className="w-full relative flex justify-center h-136 ">
            <div
              className={`w-88 flex flex-col gap-y-12 h-104 rounded-2xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__blue`}
              style={{
                backgroundColor: "",
                transform: inView
                  ? "rotate(-2deg) translateX(-30rem)"
                  : "rotate(-15deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-black p-8 leading-none">
                Supercharge LTV
              </h1>
              <p className="font-plus-jakarta-sans text-2xl font-semibold text-center">
                Increase customer lifetime value through targeted strategies.
              </p>
            </div>
            <div
              className={`w-88 flex flex-col gap-y-12 h-104 rounded-2xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__green`}
              style={{

                transform: inView
                  ? "rotate(2deg) translateX(-10rem)"
                  : "rotate(-5deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-black p-8 leading-none">
                Optimize Marketing Spend
              </h1>
              <p className="font-plus-jakarta-sans text-2xl font-semibold text-center">
                Use clear insights to create more effective campaigns.
              </p>
            </div>
            <div
              className={`w-88 flex flex-col gap-y-12 h-104 rounded-2xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-white`}
              style={{

                transform: inView
                  ? "rotate(-2deg) translateX(11rem)"
                  : "rotate(5deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-black p-8 leading-none">
                Reduce CAC
              </h1>
              <p className="font-plus-jakarta-sans text-2xl font-semibold text-center">
                Lower acquisition costs by improving retention and leveraging
                word-of-mouth.
              </p>
            </div>
            <div
              className={`w-88 flex flex-col gap-y-12 h-104 rounded-2xl p-8 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__yellow`}
              style={{

                transform: inView
                  ? "rotate(2deg) translateX(32rem)"
                  : "rotate(15deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-black p-8 leading-none">
                Boost Customer Loyalty
              </h1>
              <p className="font-plus-jakarta-sans text-2xl font-semibold text-center">
                Enhance personalized interactions and support.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Index;
