export const isMobileDevice = (): boolean => {
  const screenWidth = window.innerWidth;
  const mobileBreakpoint = 768;
  return screenWidth <= mobileBreakpoint;
};

export const commaNumber = (value?: number): string => {
  if (!value) return "0";
  return value.toLocaleString();
};

