import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { isMobileDevice } from "../utils";

const FounderMade: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="viewarea bg-black">
      {isMobileDevice() ? (
        <div className="relative w-full h-full bg-black">
          <Button
            className="absolute bottom-2 shadow-xl  text-white font-semibold w-48 h-12 left-1/3 transform -translate-x-1/2 z-50 rounded-full bg-gradient-to-r from-[#8C4DF8] to-[#FE8F92]"
            onClick={() => {
              window.open("https://calendly.com/ish_agrawal/30min", "_blank");
            }}
          >
            Book A Demo
          </Button>
          <Button
            className="absolute bottom-2 shadow-xl  text-white font-semibold w-28 h-12 left-3/4 transform -translate-x-1/2 z-50 rounded-full bg-black border border-white"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Button>
          <video
            style={{
              width: "100%",
              height: "100%",
              // objectFit: "cover",
            }}
            loop
            muted
            autoPlay
            playsInline
            disablePictureInPicture
            controls
          >
            <source
              src={
                "https://s3.amazonaws.com/helpar.io/assets/video/HelpAR+Ad.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        <div className="relative w-full h-full bg-black flex justify-center">
          <video
            className="w-1/3"
            loop
            muted
            autoPlay
            playsInline
            disablePictureInPicture
            controls
          >
            <source
              src={
                "https://s3.amazonaws.com/helpar.io/assets/video/HelpAR+Ad.mp4"
              }
              type="video/webm"
            />
          </video>
          <Button
            className="absolute bottom-2 shadow-xl  text-white font-semibold w-48 h-12 left-1/3 transform -translate-x-1/2 z-50 rounded-full bg-gradient-to-r from-[#8C4DF8] to-[#FE8F92]"
            onClick={() => {
              window.open("https://calendly.com/ish_agrawal/30min", "_blank");
            }}
          >
            Book A Demo
          </Button>
          <Button
            className="absolute bottom-2 shadow-xl  text-white font-semibold w-28 h-12 left-3/4 transform -translate-x-1/2 z-50 rounded-full bg-black border border-white"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Button>
        </div>
      )}
    </div>
  );
};

export default FounderMade;
