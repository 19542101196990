/** FIXME: https://app.asana.com/0/1206405657480018/1207476676782243/f */
import React, { useState } from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { isMobileDevice, commaNumber } from "../utils";

interface CalculatorProps {}

const Calculator: React.FC<CalculatorProps> = () => {
  const [avgPurchaseValue, setAvgPurchaseValue] = useState<string>("0");
  const [purchasesPerYear, setPurchasesPerYear] = useState<string>("0");
  const [yearsPerCustomer, setYearsPerCustomer] = useState<string>("0");
  const [totalAnnualSales, setTotalAnnualSales] = useState<string>("0");
  const [otherSalesPercentage, setOtherSalesPercentage] = useState<string>("0");
  const isMobile = isMobileDevice();

  const calculateLifetimeValue = () => {
    const apv = parseFloat(avgPurchaseValue);
    const apy = parseFloat(purchasesPerYear);
    const ypc = parseFloat(yearsPerCustomer);
    const pf = parseFloat(purchasesPerYear);
    const cl = parseFloat(yearsPerCustomer);

    if (isNaN(apv) || isNaN(apy) || isNaN(ypc) || isNaN(pf) || isNaN(cl)) {
      return 0;
    }

    if (apv === 0 || pf === 0 || cl === 0) {
      return 0;
    }

    return commaNumber(apv * pf * cl);
  };

  const calculateUntappedRevenue = () => {
    const apv = parseFloat(avgPurchaseValue);
    const apy = parseFloat(purchasesPerYear);
    const ypc = parseFloat(yearsPerCustomer);
    const pf = parseFloat(purchasesPerYear);
    const cl = parseFloat(yearsPerCustomer);
    const tas = parseFloat(totalAnnualSales);
    const otherSales = parseFloat(otherSalesPercentage) / 100;

    if (
      isNaN(apv) ||
      isNaN(apy) ||
      isNaN(ypc) ||
      isNaN(pf) ||
      isNaN(cl) ||
      isNaN(tas)
    ) {
      return 0;
    }

    if (apv === 0 || pf === 0 || cl === 0 || tas === 0) {
      return 0;
    }

    const ltv = apv * pf * cl;

    if (ltv === 0) {
      return 0;
    }

    const unowned = tas * otherSales;

    console.log("> un ", unowned);

    if (unowned === 0) {
      return 0;
    }

    return commaNumber(ltv * unowned);
  };

  // FIXME: https://app.asana.com/0/1206405657480018/1207490684801633/f
  return (
    <div className={`w-full  ${isMobile ? "flex-col" : "flex p-8"} bg-black`}>
      <div
        className={`${isMobile ? "w-full" : "w-1/2"} p-8 flex flex-col gap-y-4`}
      >
        <Label className={`font-plus-jakarta-sans text-white text-lg`}>
          What is your average purchase value?
        </Label>
        <Input
          placeholder="$100"
          className="rounded-full bg-black text-darkgray boarder-darkgray pl-6 h-12 text-lg"
          onChange={(e) => setAvgPurchaseValue(e.target.value)}
        />
        <Label className={`font-plus-jakarta-sans text-white text-lg`}>
          How many purchases does a customer make per year?
        </Label>
        <Input
          placeholder="5"
          className="rounded-full bg-black text-white  pl-6 h-12 text-lg"
          onChange={(e) => setPurchasesPerYear(e.target.value)}
        />
        <Label className={`font-plus-jakarta-sans text-white text-lg`}>
          How many years does a typical customer stay with you?
        </Label>
        <Input
          placeholder="4"
          className="rounded-full bg-black text-white  pl-6 h-12 text-lg"
          onChange={(e) => setYearsPerCustomer(e.target.value)}
        />
        <Label className={`font-plus-jakarta-sans text-white text-lg`}>
          What is your total annual sales volume?
        </Label>
        <Input
          placeholder="2,000,000"
          className="rounded-full bg-black text-white  pl-6 h-12 text-lg"
          onChange={(e) => setTotalAnnualSales(e.target.value)}
        />
        <Label className={`font-plus-jakarta-sans text-white text-lg`}>
          What percentage of your sales come from channels other than your own?
        </Label>
        <Input
          placeholder="30%"
          className="rounded-full bg-black text-white  pl-6 h-12 text-lg"
          onChange={(e) => setOtherSalesPercentage(e.target.value)}
        />
      </div>
      <div
        className={`w-${
          isMobile ? "full" : "1/2"
        } p-8 flex flex-col gap-y-4 items-center justify-center`}
      >
        <div
          style={{ backgroundColor: "#95A4FC" }}
          className="w-full h-1/2 p-8 rounded-2xl "

        >
          <p
            className={`font-plus-jakarta-sans text-${isMobile ? "lg" : "3xl"}`}
          >
            Your Customer Lifetime Value Is
          </p>
          <h1
            className={`text-${
              isMobile ? "4" : "7"
            }xl font-bold font-plus-jakarta-sans`}
          >
            ${calculateLifetimeValue()}
          </h1>
        </div>
        <div
          style={{ backgroundColor: "#B1FA63" }}
          className="w-full h-1/2 p-8 rounded-2xl"
        >
          <p
            className={`font-plus-jakarta-sans text-${isMobile ? "lg" : "3xl"}`}
          >
            Your Untapped Revenue Potential Is
          </p>
          <h1
            className={`text-${
              isMobile ? "4" : "7"
            }xl font-bold font-plus-jakarta-sans`}
          >
            ${calculateUntappedRevenue()}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
