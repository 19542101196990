import React from "react";
import Router from "./router";

import "./styles/index.css";

const App: React.FC = () => {
  return <Router />;
};

export default App;
