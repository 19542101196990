import React from "react";
import Mobile from "./index/Mobile";
import Desktop from "./index/Desktop";

import { isMobileDevice } from "../utils";

const Index: React.FC = () => {
  return isMobileDevice() ? <Mobile /> : <Desktop />;
};

export default Index;
