import React from "react";
import { useParams } from "react-router-dom";
import Solution, { SolutionProps } from "../components/Solution";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";

type Key = "f&b" | "b&c" | "h&f" | "w&s" | "ce";

const solutions: { [key in Key]: SolutionProps } = {
  "f&b": {
    title: "Food & Beverage",
    description:
      "Understand how food & beverage brands are leveraging our app to enhance customer engagement, boost retail performance, and foster habitual reordering and subscription behaviors.",
    cards: [
      {
        title: "Recipes",
        className: "bg-white",
        description:
          "Inspire customers with new and creative ways to use your products. Share recipes that highlight your ingredients, encouraging repeat purchases and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/RecipeNew.gif",
      },
      {
        title: "One Tap Reorder",
        className: "bg-white",
        description:
          "Make it easy for customers to reorder their favorite food and beverage items, ensuring they never run out of their preferred products.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/ShopNew.gif",
      },
      {
        title: "Post-purchase Surveys and Forms",
        className: "bg-white",
        description:
          "Inspire customers with new and creative ways to use your products. Share recipes that highlight your ingredients, encouraging repeat purchases and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Survey.gif",
      },
      {
        title: "Video Tutorials",
        className: "bg-white",
        description:
          "Inspire customers with new and creative ways to use your products. Share recipes that highlight your ingredients, encouraging repeat purchases and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/food+video+tutorial+new.gif",
      },
      {
        title: "Reviews",
        className: "bg-white",
        description:
          "Inspire customers with new and creative ways to use your products. Share recipes that highlight your ingredients, encouraging repeat purchases and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Review.gif",
      },
      {
        title: "Product Recomme- ndations",
        className: "bg-white",
        description:
          "Inspire customers with new and creative ways to use your products. Share recipes that highlight your ingredients, encouraging repeat purchases and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/RecommendationNew.gif",
      },
    ],
  },
  "b&c": {
    title: "Beauty & Cosmetics",
    description:
      "Understand how beauty & cosmetics brands are leveraging our app to enhance customer engagement, boost retail performance, and foster habitual reordering and subscription behaviors.",
    cards: [
      {
        title: "AI Chat Support",
        className: "bg-helpar__blue",
        description:
          "Offer personalized skincare advice and product recommendations through AI-powered chat, ensuring customers find the perfect products for their needs.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/AI Support.gif",
      },
      {
        title: "Rewards & Loyalty",
        className: "bg-helpar__purple",
        description:
          "Implement a loyalty program that rewards customers for their purchases and engagement, encouraging repeat business and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Loyalty.png",
      },
      {
        title: "Video Tutorials",
        className: "bg-helpar__green",
        description:
          "Provide makeup and skincare tutorials that educate customers on how to use your products effectively, boosting product satisfaction and usage.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/beauty+video+tutorial.gif",
      },
      {
        title: "Post-purchase Surveys and Forms",
        className: "bg-helpar__yellow",
        description:
          "Gather feedback on product performance and customer satisfaction to continuously improve your product offerings.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Survey.gif",
      },
      {
        title: "Product Recomme- ndations",
        className: "bg-helpar__blue",
        description:
          "Offer personalized beauty product recommendations based on customer preferences and purchase history, enhancing the shopping experience.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/RecommendationNew.gif",
      },
      {
        title: "Reviews",
        className: "bg-helpar__purple",
        description:
          "Encourage customers to leave reviews, providing social proof and valuable insights for potential buyers.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Review.gif",
      },
    ],
  },

  "h&f": {
    title: "Home & Furniture",
    description:
      "Understand how beauty & cosmetics brands are leveraging our app to enhance customer engagement, boost retail performance, and foster habitual reordering and subscription behaviors.",
    cards: [
      {
        title: "Augmented Reality",
        className: "bg-helpar__blue",
        description:
          "Allow customers to visualize furniture and home decor in their own space using AR, enhancing confidence in their purchasing decisions.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/ARN.gif",
      },
      {
        title: "Installation Instructions",
        className: "bg-helpar__purple",
        description:
          "Provide detailed installation guides and videos to help customers set up their furniture easily and correctly.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/install+instruction.gif",
      },
      {
        title: "One Tap Reorders",
        className: "bg-helpar__green",
        description:
          "Simplify the process of reordering home essentials and accessories, ensuring customers can maintain a cohesive home aesthetic.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/ShopNew.gif",
      },
      {
        title: "Product Recomme- ndations",
        className: "bg-helpar__yellow",
        description:
          "Suggest complementary home decor items based on previous purchases, enhancing the overall shopping experience.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/RecommendationNew.gif",
      },
      {
        title: "Reviews",
        className: "bg-helpar__blue",
        description:
          "Collect and display customer reviews to build trust and provide valuable insights to potential buyers.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Review.gif",
      },
      {
        title: "Post-purchase Surveys and Forms",
        className: "bg-helpar__purple",
        description:
          "Gather feedback on product performance and customer satisfaction to continuously improve your product offerings.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Survey.gif",
      },
    ],
  },

  "w&s": {
    title: "Wellness & Supplements",
    description:
      "Discover how wellness and supplement brands are employing our app to gather valuable customer insights, promote consistent product use, and build enduring loyalty through targeted engagement strategies..",
    cards: [
      {
        title: "NPS Score Collection & Feedback",
        className: "bg-helpar__blue",
        description:
          "Measure customer satisfaction and loyalty, gaining insights into how your products impact their well-being.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Review.gif",
      },
      {
        title: "Rewards & Loyalty",
        className: "bg-helpar__purple",
        description:
          "Implement a loyalty program that rewards customers for their purchases and engagement, encouraging repeat business and brand loyalty.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Loyalty.png",
      },
      {
        title: "Progress Tracking",
        className: "bg-helpar__green",
        description:
          "Track customer progress by providing detailed information on supplement benefits and usage, ensuring customers are well-informed and confident in their purchases.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Progress+Tracking.gif",
      },
      {
        title: "Post-purchase Surveys and Forms",
        className: "bg-helpar__yellow",
        description:
          "Gather feedback on product performance and customer satisfaction to continuously improve your product offerings.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Survey.gif",
      },
      {
        title: "One Tap Reorders",
        className: "bg-helpar__blue",
        description:
          "Make it easy for customers to reorder their favorite supplements, ensuring they stay consistent with their wellness routines.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/ShopNew.gif",
      },
      {
        title: "Video Tutorials",
        className: "bg-helpar__purple",
        description:
          "Offer tutorials on how to incorporate supplements into daily routines, enhancing product utilization and customer satisfaction.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/wellness+video+tutorial.png",
      },
    ],
  },

  "ce": {
    title: "Consumer Electronics",
    description:
      "Learn how consumer electronics brands are optimizing customer support and satisfaction by integrating our app to provide real-time assistance, streamline installations, and enhance overall product experiences.",
    cards: [
      {
        title: "AI Chat Support",
        className: "bg-helpar__blue",
        description:
          "Offer personalized skincare advice and product recommendations through AI-powered chat, ensuring customers find the perfect products for their needs.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/AI Support.gif",
      },
      {
        title: "Installation Instructions",
        className: "bg-helpar__purple",
        description:
          "Offer comprehensive setup guides and videos to help customers install and configure their new electronics correctly.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/install+instruction.gif",
      },
      {
        title: "Quiz",
        className: "bg-helpar__green",
        description:
          "Leverage our Quiz module to engage your customers through dynamic, interactive quizzes. This tool allows you to gather valuable insights about customer preferences, enabling you to tailor your products and services for a more personalized user experience.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/QuizNew.gif",
      },
      {
        title: "Reviews",
        className: "bg-helpar__yellow",
        description:
          "Encourage customers to leave reviews, offering social proof and valuable insights for potential buyers.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Review.gif",
      },
      {
        title: "Product Recomme- ndations",
        className: "bg-helpar__blue",
        description:
          "Offer personalized beauty product recommendations based on customer preferences and purchase history, enhancing the shopping experience.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/RecommendationNew.gif",
      },
      {
        title: "NPS Score Collection & Feedback",
        className: "bg-helpar__purple",
        description:
          "Collect feedback on product performance and customer satisfaction to drive continuous improvement and innovation.",
        imageURL:
          "https://s3.amazonaws.com/helpar.io/assets/img/modules/Survey.gif",
      },
    ],
  },
};

const Solutions: React.FC = () => {
  const { solution } = useParams();
  const s = solutions[(solution || "f&b") as Key];
  return (
    <div className={`viewarea bg-black`}>
      <TopNavigation className="absolute" />
      <div className="overflow-y-auto h-screen">
        <Solution {...s} />
        <Footer />
      </div>
    </div>
  );
};

export default Solutions;
