/** FIXME: https://app.asana.com/0/1206405657480018/1207476676782243/f */
import React, { useState, useRef, useEffect } from "react";
import Footer from "../../components/Footer";
import TopNavigation from "../../components/TopNavigation";
import { Button } from "../../components/ui/button";

import "./../../styles/animation.css";

const Index: React.FC = () => {
  const unlockValueCardContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const blurContainerRef = useRef<HTMLDivElement | null>(null);
  const [blurAmount, setBlurAmount] = useState(90);
  const [inView, setInView] = useState(false);
  const [
    connectWithCustomersHeaderVisible,
    setConnectWithCustomersHeaderVisible,
  ] = useState(false);
  const connectWithCustomersHeaderRef = useRef<HTMLHeadingElement | null>(null);
  const lessThanOptimalExperienceRef = useRef<HTMLHeadingElement | null>(null);
  const [
    lessThanOptimalExperienceVisible,
    setLessThanOptimalExperienceVisible,
  ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [textInView, setTextInView] = useState(false);
  const [
    unlockValueCardContainerRefIsScrollable,
    setUnlockValueCardContainerRefIsScrollable,
  ] = useState<boolean>(false);

  useEffect(() => {
    const words = document.querySelectorAll(".word");
    words.forEach((word: any, index) => {
      word.style.animationDelay = `${index * 0.2}s`;
    });
  }, []);

  useEffect(() => {
    const element = lessThanOptimalExperienceRef.current;
    if (!element) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setLessThanOptimalExperienceVisible(true);
          observer.unobserve(element);
        }
      });
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const element = connectWithCustomersHeaderRef.current;
    if (!element) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setConnectWithCustomersHeaderVisible(true);
          observer.unobserve(element);
        }
      });
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const element = document.querySelector(".falling-text");

    if (!element) return;

    const handleIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const textContent = element.textContent;
        element.innerHTML = "";

        if (textContent) {
          textContent.split("").forEach((char, index) => {
            const span = document.createElement("span");
            span.textContent = char === " " ? "\u00A0" : char;
            span.style.animationDelay = `${index * 0.1}s`;
            element.appendChild(span);
          });
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }
  }, []);

  useEffect(() => {
    const element = document.querySelector(".typewriter-text");

    if (!element) return;

    let originalText = element?.textContent;
    let currentText = "";
    let index = 0;

    const handleTypewriterIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const typeCharacter = () => {
          if (!originalText) return;

          if (index < originalText.length) {
            currentText += originalText[index];
            element.textContent = currentText;
            index++;
            setTimeout(typeCharacter, 60);
          }
        };
        typeCharacter();
      }
    };

    const observer = new IntersectionObserver(handleTypewriterIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".blur-text");

    const handleBlurIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setTextInView(true);
      }
    };

    const observer = new IntersectionObserver(handleBlurIntersection, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const handleScroll = () => {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (unlockValueCardContainerRef.current) {
        const cardRect =
          unlockValueCardContainerRef.current.getBoundingClientRect();

        const diff = 0.2;

        if (
          cardRect.top >= -diff * viewportHeight &&
          cardRect.bottom <= viewportHeight * (1 + diff)
        ) {
          setInView(true);
        } else {
          setInView(false);
        }

        if (cardRect.top <= 0) {
          setUnlockValueCardContainerRefIsScrollable(true);
        } else {
          setUnlockValueCardContainerRefIsScrollable(false);
        }
      }

      if (blurContainerRef.current) {
        const bgBlueRect = blurContainerRef.current.getBoundingClientRect();
        const maxBlur = 50;
        let blur = 0;
        const diff = 0.4;

        if (bgBlueRect.top >= -diff * viewportHeight) {
          blur = Math.max((bgBlueRect.top / viewportHeight) * maxBlur, 0);
        } else if (bgBlueRect.bottom <= viewportHeight) {
          blur = Math.max(
            (1 - bgBlueRect.bottom / viewportHeight) * maxBlur,
            0,
          );
        }

        setBlurAmount(blur);
      }
    };

    scrollContainer?.addEventListener("scroll", handleScroll);
    scrollContainer?.addEventListener("resize", handleScroll);

    handleScroll();

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
      scrollContainer?.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <div className={`viewarea bg-black`}>
      <TopNavigation className="absolute" />
      <div className="overflow-auto h-screen relative" ref={scrollContainerRef}>
        <div className="w-full h-full flex flex-col justify-center items-center relative">
        <img
            src="https://s3.amazonaws.com/helpar.io/assets/img/CommercialWebsiteMobile.gif"
            alt="banner cover"
            className="w-full h-full  absolute"
          />
        <img
                    src="/logo192-white.png"
                    alt="helpar-logo"
                    className="h-8 w-8 cursor-pointer mix-blend-difference"
                  />
          <h3 className="text-white font-medium opacity-100 text-2xl text-center mix-blend-difference font-plus-jakarta-sans">
            HelpAR Vision
          </h3>
          <h1 className="text-white font-extrabold mix-blend-difference text-8xl/[4rem] text-center typewriter-text  font-plus-jakarta-sans">
          Bring Your Customers Into Focus
          </h1>
         
        </div>
        <div className="w-full h-224 p-4">
          <div className="w-full h-full relative flex flex-col">
            <div
              className={`w-full    ${
                connectWithCustomersHeaderVisible ? "fade-in" : "fade-in"
              }`}
              ref={connectWithCustomersHeaderRef}
            >
              <h1
                className={`font-semibold font-plus-jakarta-sans text-6xl text-white leading-none text-center mt-12`}
              >
                Connect With Your Customers, In Their World
              </h1>
            </div>
            <div className="w-full  bottom-16 flex items-center justify-center flex-col pt-8">
              <p
                className={`font-plus-jakarta-sans text-xl text-white text-center`}
              >
                Your customers are right there, hidden in plain sight. Bring
                them into sharper focus with HelpAR and unlock their true value.
              </p>
              <Button
                className="rounded-full w-56 p-7 text-lg font-plus-jakarta-sans mt-4 bg-gradient-to-r from-[#95A4FC] to-[#B1FA63]"
                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Book A Demo
              </Button>
            </div>
            <img
              src="https://s3.amazonaws.com/helpar.io/assets/img/mockup.png"
              alt="banner cover"
              className="w-full h-62  pt-12 "
            />
            
          </div>
        </div>
        <div
          className={`w-full bg-black mt-20 flex justify-center items-center `}
        >
          <div className="flex flex-col w-full p-4">
            <h1 className="font-semibold text-center font-plus-jakarta-sans text-5xl text-gray leading-none">
              Unlock Your Biggest Hidden Asset:
            </h1>
            <h1 className="font-bold text-center font-plus-jakarta-sans text-7xl text-white leading-none mt-6 falling-text">
              Post Purchase
            </h1>
          </div>
        </div>
        <div
          className={`w-full bg-black  p-8`}
          ref={blurContainerRef}
          style={{
            filter: `blur(${blurAmount}px)`,
          }}
        >
          <div className="relative  h-full w-full flex flex-col gap-y-4 mt-24">
            <div className=" w-full">
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-white leading-none">
                The post purchase customer journey is blurry at best.
              </h1>
            </div>
            <div className=" w-full">
              <p className="text-lightgray font-plus-jakarta-sans text-xl text-center mt-8">
                Wouldn’t it be great to know exactly how your customers are
                using your products? Or why they chose them in the first place?
                And let’s be honest, even figuring out who your customers
                actually are can be a real challenge. Right now, you're piecing
                together fragmented data from multiple channels, and it’s nearly
                impossible to get a clear picture.
              </p>
            </div>
          </div>
        </div>
        <img
                src="https://s3.amazonaws.com/helpar.io/assets/img/BGlighting.png"
                alt="banner cover"
                className=" h-144  blur-[160px] absolute"
              />
        <div
          className={`w-full bg-black p-8`}
          ref={lessThanOptimalExperienceRef}
        >
          <div className=" w-full">
        
          </div>
          
          <div className="relative  h-full w-full flex flex-col gap-y-4 mt-24">
          
            <div className=" w-full">
            
              <h1 className="font-bold font-plus-jakarta-sans text-6xl text-center text-white leading-none">
                {"Resulting in a less than optimal experience for your customers."
                  .split(" ")
                  .map((word, index) => (
                    <span
                      key={index}
                      className={`falling-word`}
                      style={{
                        animationDelay: lessThanOptimalExperienceVisible
                          ? `${1.5 + index * 0.3}s`
                          : "0s",
                      }}
                    >
                      {word}
                    </span>
                  ))}
              </h1>
              
              <p className="text-lightgray font-plus-jakarta-sans text-xl text-center mt-8 ">
                Customers face their own set of headaches, constantly jumping
                between different platforms to find product information and
                support. They're overwhelmed by the scattered and fragmented
                process, leaving them feeling disconnected from your brand. They
                want to connect with you, but it’s a frustrating and cumbersome
                journey.
              </p>
              
              
            </div>
          </div>
        </div>
        <div className={`w-full bg-black mt-24 p-8`}>
          <div className="relative  h-full w-full flex flex-col gap-y-4">
            <div className=" w-full">
              <h1 className="font-bold font-plus-jakarta-sans text-8xl text-center text-white leading-none typewriter-text helpar-gradient-text">
                Until Now.
              </h1>
            </div>
            <div className="w-full">
              <p className="text-lightgray font-plus-jakarta-sans text-xl text-center mt-4">
                HelpAR cuts through the chaos. For you, it’s like putting on
                goggles that bring into focus a clear view of your customers and
                their behaviors. For your customers, it means a smooth,
                integrated post-purchase experience that makes connecting with
                your brand effortless. With HelpAR, meaningful connections with
                your customers post purchase becomes not just possible, but
                powerfully simple.
              </p>
            </div>
          </div>
        </div>
        <div className="h- w-full mb-12 mt-24 ">
          <h1 className="font-bold text-left font-plus-jakarta-sans text-5xl text-white leading-none ml-8">
            With A Single Tap, HelpAR Blends The…
          </h1>
          <div className="h-184 w-full gap-x-6 mt-6 overflow-x-auto flex flex-wrap-nowrap pl-8">
            <div
              className="relative rounded-2xl w-72 flex-shrink-0 flex flex-col justify-between h-160 bg-helpar__blue"
              style={{ backgroundColor: "" }}
            >
              <div className="w-full h-full p-4 flex flex-col">
                <h1 className="font-bold font-plus-jakarta-sans text-4xl text-center text-black p-4 leading-none">
                  Power of Data
                </h1>
                <p className="font-plus-jakarta-sans text-md text-center text-black">
                  Dive deep into rich analytics and customer insights, turning
                  every interaction into actionable intelligence that drives
                  strategic decisions and fosters brand growth.
                </p>
              </div>
              <div className=" w-full h-114 flex items-center justify-center pb-8">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/1.+Power+of+data.gif"
                  className="h-112 w-56 rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="relative rounded-2xl w-82 flex-none flex flex-col justify-between h-160 bg-helpar__green"
              style={{ backgroundColor: "" }}
            >
              <div className="w-full h-full p-4 flex flex-col">
              <h1 className="font-bold font-plus-jakarta-sans text-4xl text-center text-black p-4 leading-none">
                  Engaging, Intuitive UX
                </h1>
                <p className="font-plus-jakarta-sans text-md text-center text-black">
                  Our user-friendly interface ensures that both your team and
                  your customers enjoy smooth, engaging interactions, making
                  every touchpoint a delight.
                </p>
              </div>
              <div className=" w-full h-114 flex items-center justify-center pb-8">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/2.+UX.gif"
                  className="h-112 w-56 rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="relative rounded-2xl w-72 flex-shrink-0 flex flex-col justify-between h-160"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div className="w-full h-full p-4 flex flex-col">
              <h1 className="font-bold font-plus-jakarta-sans text-2xl text-center  text-black p-4 leading-none">
                  Simplicity of Templates
                </h1>
                <p className="font-plus-jakarta-sans text-md text-center text-black">
                  Designed for efficiency and impact, our templatized modules
                  allow you to quickly craft compelling post-purchase
                  experiences that resonate with your customers.
                </p>
              </div>
              <div className=" w-full h-114 flex items-center justify-center pb-8">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/3.+Template.gif"
                  className="h-112 w-56 rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
            <div
              className="relative rounded-2xl w-72 flex-shrink-0 flex flex-col justify-between h-160 bg-helpar__yellow"
              style={{ backgroundColor: "" }}
            >
              <div className="w-full h-full p-4 flex flex-col">
              <h1 className="font-bold font-plus-jakarta-sans text-4xl text-center  text-black p-4 leading-none">
                  Intelligence of AI
                </h1>
                <p className="font-plus-jakarta-sans text-md text-center">
                  HelpAR AI offers personalized customer interactions and
                  support, ensuring that each customer feels uniquely understood
                  and valued, while providing you with smart insights for
                  continuous improvement.
                </p>
              </div>
              <div className=" w-full h-114 flex items-center justify-center pb-8">
                <img
                  src="https://s3.amazonaws.com/helpar.io/assets/img/4.+AI.gif"
                  className="h-112 w-56 rounded-2xl"
                  alt="benefits-card"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-full  p-8 flex flex-col items-center ${
            unlockValueCardContainerRefIsScrollable ? "overflow-y-auto" : ""
          }`}
          ref={unlockValueCardContainerRef}
        >
          <div className="w-full  mb-12 gapy-y-84 flex flex-col">
            <h1 className="font-bold text-center plus-jakarta-sans text-5xl text-gray leading-none mb-2">
              Let Us Help You.
            </h1>
            <h1
              className={`font-bold text-center plus-jakarta-sans text-5xl text-white leading-none`}
            >
              See The Unseen.
            </h1>
          </div>
          <div className="w-full relative flex h-64 justify-center mt-8">
            <div
              className={`w-40 flex flex-col gap-y-4 h-56 rounded-xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__blue`}
              style={{

                transform: inView
                  ? "rotate(-8deg) translateX(-8rem)"
                  : "rotate(-15deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-3xl text-center text-black leading-none">
                Supercharge LTV
              </h1>
              <p className="font-plus-jakarta-sans text-md font-semibold text-center">
                Increase customer lifetime value through targeted strategies.
              </p>
            </div>
            <div
              className={`w-40 flex flex-col gap-y-4 h-56 rounded-xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__green`}
              style={{
                transform: inView
                  ? "rotate(2deg) translateX(-3rem)"
                  : "rotate(-5deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-3xl text-center text-black leading-none">
                Optimize Marketing Spend
              </h1>
              <p className="font-plus-jakarta-sans text-md font-semibold text-center">
                Use clear insights to create more effective campaigns.
              </p>
            </div>
            <div
              className={`w-40 flex flex-col gap-y-4 h-56 rounded-xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg`}
              style={{
                backgroundColor: "#ffffff",
                transform: inView
                  ? "rotate(-2deg) translateX(3rem)"
                  : "rotate(5deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-3xl text-center text-black leading-none">
                Reduce CAC
              </h1>
              <p className="font-plus-jakarta-sans text-md font-semibold text-center">
                Lower acquisition costs by improving retention and leveraging
                word-of-mouth.
              </p>
            </div>
            <div
              className={`w-40 flex flex-col gap-y-4 h-56 rounded-xl p-4 absolute transition-transform transition-top duration-300 ease-in-out shadow-lg bg-helpar__yellow`}
              style={{
                transform: inView
                  ? "rotate(4deg) translateX(8rem)"
                  : "rotate(15deg)",
              }}
            >
              <h1 className="font-bold font-plus-jakarta-sans text-3xl text-center text-black leading-none ">
                Boost Customer Loyalty
              </h1>
              <p className="font-plus-jakarta-sans text-md font-semibold text-center">
                Enhance personalized interactions and support.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Index;
