import React from "react";
import { isMobileDevice } from "../utils";
import { Button } from "./ui/button";


export interface SolutionCardProps {
  title: string;
  description: string;
  imageURL: string;
  className?: string;
}

const SolutionCard: React.FC<SolutionCardProps> = ({
  title,
  description,
  imageURL,
  className,
}) => {
  const isMobile = isMobileDevice();
  return (
    <div
      className={` ${
        isMobile ? "w-full" : "w-full h-224 sm:w-[calc(50%-2rem)] md:w-[calc(50%-2rem)] lg:w-[calc(50%-2rem)] "
      } p-8 flex items-center justify-center ${className} bg-white rounded-3xl`}
    >
      <div
        className={`${

          isMobile ? "w-full" : "w-1/2"

        } flex  flex-col h-full ${isMobile ? "p-2" : "p-4"}`}
      >
        
        <div className={`${
        isMobile ? "" :"h-full flex flex-col items-left justify-center justify-between"}`}>
        
        <h1
          className={`font-plus-jakarta-sans text-black font-bold ${
            isMobile ? "text-4xl text-center" : "text-8xl/[4rem] text-left"
          }`}
        >
          {title}
        </h1>
        <div className="flex flex-col items-left  ">
        <p
          className={`text-black font-plus-jakarta-sans  ${
            isMobile ? "w-full text-lg  pt-2 text-center" : " text-3xl pt-6 opacity-70 text-left"
          }`}
        >
          {description}
        </p>
        <div className={`pt-6 ${
                  isMobile ? "flex justify-center items-center" : ""
                }`}>
        <Button
                className={`rounded-full    ${
                  isMobile ? "text-md p-5 w-32" : " text-lg p-7 w-48"
                }  font-plus-jakarta-sans text-black font-semibold bg-gradient-to-r from-[#95A4FC] to-[#B1FA63]`}
                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Learn More
              </Button>
        </div>
        

        </div>
       
        </div>
        {isMobile ? (

          <div className="w-full flex items-center justify-center mt-6">

            <img src={imageURL} alt={title} className=" rounded-xl" />
          </div>
        ) : (
          <></>
        )}
      </div>

      {isMobile ? (
        <></>
      ) : (

        <div className="w-1/2 flex items-end">
          {" "}
          <img src={imageURL} alt={title} className="h-200 rounded-3xl p-2 " />

        </div>
      )}
    </div>
  );
};

export default SolutionCard;
