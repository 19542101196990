import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { isMobileDevice } from "../utils";
import { Button } from "./ui/button";


interface TopNavigationProps {
  className?: string;
}

const TopNavigation: React.FC<TopNavigationProps> = ({ className }) => {
  const navigate = useNavigate();

  if (isMobileDevice()) {
    return (
      <div className={`w-20 flex cursor-pointer ${className}`}>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="text-lg font-plus-jakarta-sans text-white">
                <div
                  className="mt-8 flex flex-col items-center justify-center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/helpar.io/assets/img/logo%2Btext_helpar.png"
                    alt="helpar-logo"
                    className="h-16 w-16 cursor-pointer z-50"
                  />
                  {/* <h1 className="font-semibold font-plus-jakarta-sans text-white text-xl">
                    HelpAR
                  </h1> */}
                </div>
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <div className="w-screen bg-black flex flex-col p-4 z-100">
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white ml-4 mt-4"
                    onClick={() => {
                      window.open(
                        "https://calendly.com/ish_agrawal/30min",
                        "_blank",
                      );
                    }}
                  >
                    Book A Demo
                  </h1>
                  <hr className="border-gray mt-2" />
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white ml-4 mt-4"
                    onClick={() => {
                      navigate("/pricing");
                    }}
                  >
                    Pricing
                  </h1>
                  <hr className="border-gray mt-2" />
                  <h1 className="font-plus-jakarta-sans text-xl text-white ml-4 mt-4">
                    Solutions
                  </h1>

                  <h1
                    className="font-plus-jakarta-sans text-xl text-white ml-12 mt-2"
                    onClick={() => {
                      navigate("/solutions/f&b");
                    }}
                  >
                    » Food & Beverage
                  </h1>
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white mt-2 ml-12"
                    onClick={() => {
                      navigate("/solutions/b&c");
                    }}
                  >
                    » Beauty & Cosmetics
                  </h1>
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white ml-12 mt-2"
                    onClick={() => {
                      navigate("/solutions/h&f");
                    }}
                  >
                    » Home & Furniture
                  </h1>
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white mt-2 ml-12"
                    onClick={() => {
                      navigate("/solutions/w&s");
                    }}
                  >
                    » Wellness & Supplements
                  </h1>
                  <h1
                    className="font-plus-jakarta-sans text-xl text-white mt-2 ml-12"
                    onClick={() => {
                      navigate("/solutions/ce");
                    }}
                  >
                    » Consumer Electronics
                  </h1>
                  <hr className="border-gray mt-2" />
                  <a
                    className="font-plus-jakarta-sans text-xl text-white ml-4 mt-4"
                    href="mailto:hello@helpar.io"
                  >
                    Contact
                  </a>
                </div>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    );
  }
  return (
    <div
      className={`w-full h-16 bg-black flex gap-x-4 justify-end z-50 ${className} pr-4`}
    >
      <div className="ml-8 flex items-center justify-center  hover:opacity-80">
        <img
          src="/logo192-white.png"
          alt="helpar-logo"
          className="h-8 w-8 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
        <img
          src="https://s3.amazonaws.com/helpar.io/assets/img/logo-text.png"
          alt="helpar-logo"
          className="w-32 cursor-pointer "
          onClick={() => {
            navigate("/");
          }}
        />
        {/* <h1
          className="text-white font-semibold font-plus-jakarta-sans ml-2 cursor-pointer hover:opacity-80"
          onClick={() => {
            navigate("/");
          }}
        >
          HelpAR
        </h1> */}
      </div>
      <div className="w-full flex justify-end">
        
        <div className="w-32 h-full flex relative cursor-pointer">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger className="text-lg font-plus-jakarta-sans text-white">

                  Solutions
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <div className="w-124 bg-black flex flex-col items-center z-50">
                    <div
                      className="w-full p-4 flex flex-col hover:opacity-80 pointer-cursor "
                      onClick={() => {
                        navigate("/solutions/f&b");
                      }}
                    >
                      <h1 className="font-plus-jakarta-sans font-semibold text-lg text-white">
                        Food & Beverage
                      </h1>
                      <p className="font-plus-jakarta-sans text-md text-gray">
                        Understand how food & beverage brands are leveraging our
                        app to enhance customer engagement.
                      </p>
                    </div>
                    <div
                      className="w-full flex flex-col hover:opacity-80 pointer-cursor pl-4 "
                      onClick={() => {
                        navigate("/solutions/b&c");
                      }}
                    >
                      <h1 className="font-plus-jakarta-sans font-semibold text-lg text-white">
                        Beauty & Cosmetics
                      </h1>
                      <p className="font-plus-jakarta-sans text-md text-gray">
                        Understand how beauty & cosmetics brands are leveraging
                        our app to boost retail performance.
                      </p>
                    </div>

                    <div
                      className="w-full flex flex-col hover:opacity-80 pointer-cursor p-4"
                      onClick={() => {
                        navigate("/solutions/h&f");
                      }}
                    >
                      <h1 className="font-plus-jakarta-sans font-semibold text-lg text-white">
                      Home & Furniture
                      </h1>
                      <p className="font-plus-jakarta-sans text-md text-gray">
                      See how home and furniture brands are transforming customer interactions by utilizing our app to offer immersive experiences.
                      </p>
                    </div>

                    <div
                      className="w-full flex flex-col hover:opacity-80 pointer-cursor pl-4 "
                      onClick={() => {
                        navigate("/solutions/w&s");
                      }}
                    >
                      <h1 className="font-plus-jakarta-sans font-semibold text-lg text-white">
                      Wellness & Supplements
                      </h1>
                      <p className="font-plus-jakarta-sans text-md text-gray">
                      Discover how wellness and supplement brands are employing our app to gather valuable customer insights.
                      </p>
                    </div>

                    <div
                      className="w-full flex flex-col hover:opacity-80 pointer-cursor p-4 pb-6"
                      onClick={() => {
                        navigate("/solutions/ce");
                      }}
                    >
                      <h1 className="font-plus-jakarta-sans font-semibold text-lg text-white">
                      Consumer Electronics
                      </h1>
                      <p className="font-plus-jakarta-sans text-md text-gray">
                      Learn how consumer electronics brands are optimizing customer support and satisfaction.
                      </p>
                    </div>
                  </div>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="w-32 h-full flex items-center justify-center cursor-pointer hover:opacity-50">
          <h1
            className="font-plus-jakarta-sans text-white text-lg"
            onClick={() => {
              navigate("/pricing");
            }}
          >
            Pricing
          </h1>
        </div>
        <div
          className={`w-32 h-full flex items-center justify-center cursor-pointer hover:opacity-50`}
        >
          <a
            href="mailto:hello@helpar.io"
            className="text-lg font-plus-jakarta-sans text-white"
          >
            Contact
          </a>
        </div>

        <div
          className={` h-full flex items-center justify-center cursor-pointer hover:opacity-80`}
        >
              <Button
                className="rounded-full  w-36 text-lg font-plus-jakarta-sans bg-white "

                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Book A Demo
              </Button>
        </div>

    



        {/* <div
          onClick={() =>
            window.open("https://calendly.com/ish_agrawal/30min", "_blank")
          }
          className={`ml-12 w-32 h-full flex items-center justify-center cursor-pointer hover:opacity-50`}
        >
          <p className="text-md font-plus-jakarta-sans text-white">
            Book A Demo
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default TopNavigation;
