import React from "react";
import { isMobileDevice } from "../utils";
import { Button } from "./ui/button";


const Footer: React.FC = () => {
  if (isMobileDevice()) {
    return (
      <div
        className={`w-full p-8 flex flex-col mb-24 border-t border-darkergray bg-black text-left gap-y-4 mt-12`}
      >
        <h1
          className={`font-plus-jakarta-sans font-semibold text-xl text-white`}
        >
          Contact Us
        </h1>
        <a className={`text-lg text-white`} href="/#">
          Say Hi
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`text-lg text-white`}
          onClick={() => {
            window.open("https://calendly.com/ish_agrawal/30min", "_blank");
          }}
        >
          Book A Demo
        </a>
        <a className={`text-lg text-white`} href="mailto:webmaster@helpar.app">
          Report An Issue
        </a>
        <h1
          className={`font-plus-jakarta-sans font-semibold text-xl text-white`}
        >
          About HelpAR
        </h1>
        <a className={`text-lg text-white`} href="/pricing">
          Pricing
        </a>
        <a className={`text-lg text-white`} href="/#">
          Team
        </a>
        <a className={`text-lg text-white`} href="/#">
          Careers
        </a>
        <h1
          className={`font-plus-jakarta-sans font-semibold text-xl text-white`}
        >
          Get Informed
        </h1>
        <a className={`text-lg text-white`} href="/privacy">
          Privacy Policy
        </a>
        <a className={`text-lg text-white`} href="/terms">
          Terms Of Usage
        </a>
        <a className={`text-lg text-white`} href="/#">
          Investor Relations
        </a>
      </div>
    );
  }

  return (
      

    <div className="w-full flex flex-col bg-blackblack h-4/5 mt-12">
      <div className="w-full h-3/5 flex flex-row">
        <div className="w-4/5 h-3/5 flex flex-col">
          <h2 className="p-10 text-white font-light font-plus-jakarta-sans text-5xl">
          Meet your customers where <br></br>they are, in the real world.
          </h2>
          <h1 className="p-10 text-white font-bold font-plus-jakarta-sans text-17xl/[6rem]">HelpAR</h1>

        </div>

        <div className="w-1/5">
        <Button
                className="rounded-full w-36 p-5 text-md ml-8 mt-8 font-plus-jakarta-sans border border-white text-white"
                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Book A Demo
              </Button>
        </div>

      </div>
      <div className={`w-full p-10 flex `}>
        <div className="border border-t-1  border-blackblack border-t-darkergray flex w-full pt-8 ">
        <div
          className={`w-1/3 h-full flex flex-col gap-y-4 `}
        >
          <h1
            className={`font-plus-jakarta-sans text-darkergray text-md text-darkgray`}
            onClick={() => {
              window.open("mailto:ish@helpar.io", "_blank");
            }}
          >
            CONTACT
          </h1>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            onClick={() => {
              window.open("mailto:rashad@helpar.io", "_blank");
            }}
          >
            Say Hi
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            onClick={() => {
              window.open("https://calendly.com/ish_agrawal/30min", "_blank");
            }}
          >
            Book A Demo
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="mailto:webmaster@helpar.app"
          >
            Report An Issue
          </a>
        </div>
        <div className="w-1/3 h-full flex flex-col gap-y-4 ">
          <h1
            className={`font-plus-jakarta-sans text-md text-darkergray`}
          >
            ABOUT
          </h1>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/pricing"
          >
            Pricing
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/solutions"
          >
            Solutions
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/#"
          >
            Team
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/#"
          >
            Careers
          </a>
        </div>
        <div className="w-1/3 h-full flex flex-col gap-y-4 ">
          <h1
            className={`font-plus-jakarta-sans text-md text-darkergray`}
          >
            INFO
          </h1>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/privacy"
          >
            Privacy Policy
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/terms"
          >
            Terms of Usage
          </a>
          <a
            className={`text-md text-white cursor-pointer hover:opacity-80`}
            href="/#"
          >
            Investor Relations
          </a>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Footer;
