import React from "react";
import TopNavigation from "../components/TopNavigation";
import { isMobileDevice } from "../utils";

const Privacy: React.FC = () => {
  const isMobile = isMobileDevice();
  return (
    <div className="viewarea bg-black flex flex-col items-center overflow-y-auto">
      <TopNavigation />
      <div
        className={`p-8 mb-36 ${
          isMobile ? "w-full" : "w-1/2"
        } flex flex-col gap-y-4`}
      >
        <h1
          className={`font-plus-jakarta-sans text-white font-bold text-${
            isMobile ? "7" : "10"
          }xl`}
        >
          Privacy
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          Last Updated: 03/15/2024
        </p>

        <p className="font-plus-jakarta-sans text-white">
          This Privacy Policy applies to the HelpAR services (the “Platform”),
          which include HelpAR apps, websites, software, and related services
          accessed via any platform or device that links to this Privacy Policy.
          The Platform is provided and controlled by HelpAR Inc. (“HelpAR”,
          “we”, “us”, or “our”). We are committed to protecting and respecting
          your privacy.
        </p>

        <p className="font-plus-jakarta-sans text-white ">
          {" "}
          This Privacy Policy explains how we collect, use, share, and otherwise
          process personal information of users and other individuals age 13 and
          over in connection with our Platform. We are also GDPR compliant,
          reflecting our commitment to data protection and privacy for users
          within the European Union.
        </p>
        <p className="font-plus-jakarta-sans text-white ">
          What Information We Collect We categorize the information we collect
          as follows, providing specific examples for transparency: Provided by
          you: This includes account and profile information, user-generated
          content, messages, and purchase information. Collected automatically:
          Information such as internet or other network activity, usage data,
          device identifiers, and location data. From other sources: We may
          receive information about you from third-party services and public
          databases.
        </p>

        <p className="font-plus-jakarta-sans text-white ">
          How Data is Used at HelpAR We leverage user- generated content to
          provide brands with insights, allowing them to tailor their strategies
          more effectively. Data is used to: Understand our customer base and
          uncover patterns and behaviors. Enhance customer relationships and
          increase Lifetime Value (LTV). Improve user experience, content
          relevance, and overall engagement on the platform. In addition to the
          uses outlined, we specifically use data to comply with legal
          obligations, improve platform security, and conduct analytics and
          measurement to understand how our services are used. We ensure that
          all data use complies with GDPR provisions for data minimization and
          purpose limitation. Data Security and Retention Data is securely
          stored within a database located behind a virtual private cloud (VPC),
          with access exclusively through the HelpAR enterprise dashboard. We
          retain information as long as necessary to provide the Platform and
          for other purposes set out in this Privacy Policy. We implement strict
          security measures to protect your data, including encryption, access
          controls, and secure storage. Our retention policies ensure data is
          not kept longer than necessary for the purposes outlined in this
          policy or as required by law, considering our contractual obligations
          and users' expectations. Your Rights Under GDPR and other applicable
          laws, you have comprehensive rights regarding your personal data,
          including: The right to be informed about how your personal data is
          being used. The right to access the personal data we hold about you.
          The right to rectification if your information is inaccurate or
          incomplete. The right to erasure ("right to be forgotten") in certain
          circumstances. The right to restrict processing of your personal data.
          The right to object to processing in certain circumstances, including
          for direct marketing. You have the right to access, correct, delete,
          or appeal decisions about your personal information. Requests can be
          submitted via our dedicated link: https://www.helpar.io/privacy. We do
          not discriminate based on the exercise of any privacy rights.
        </p>

        <p className="font-plus-jakarta-sans text-white ">
          How We Share Your Information We clarify the circumstances under which
          your information may be shared with third parties, including
          compliance with legal obligations, responding to legal requests,
          enforcing our terms and policies, and protecting the rights and safety
          of HelpAR and its users. Any data sharing will respect the principles
          of necessity, proportionality, and data protection. Your Choices You
          can control some of the information we collect through device or
          browser settings, and opt out of targeted advertising and marketing
          emails. Expanding on user choices, we explain how you can manage
          consent and preferences for data collection and use, including
          mechanisms for opting out of data collection on our Platform and
          through third-party tracking. Privacy Policy Updates We commit to
          providing more specific notices via email or through our platform for
          any significant updates to this policy, ensuring users have clear
          information on changes that might affect their privacy rights.
        </p>

        <p className="font-plus-jakarta-sans text-white ">
          Contact Us Questions, comments, and requests regarding this Privacy
          Policy should be addressed to:{" "}
          <a
            className="text-helpar__purple"
            href="https://www.helpar.io/privacy"
          >
            https://www.helpar.io/privacy
          </a>
          . This Privacy Policy provides a comprehensive overview of our data
          practices, ensuring transparency and accountability in how we handle
          user information. For specific details or inquiries, please contact us
          through the provided channels.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
