import React from "react";
import { Button } from "./ui/button";
import SolutionCard, { SolutionCardProps } from "./SolutionCard";
import { isMobileDevice } from "../utils";

export interface SolutionProps {
  title: string;
  description: string;
  cards: SolutionCardProps[];
}

const Solution: React.FC<SolutionProps> = ({ title, description, cards }) => {
  const isMobile = isMobileDevice();
  return (
    <div
      className={`w-full flex items-center flex-col ${
        isMobile ? "p-2" : "p-16"
      }`}
    >
      <div className="w-full relative flex flex-col">
           
            <div className={` ${isMobile ? "w-full" : "w-3/5"} pt-24`}>
              <h1
                className={`font-bold fade-in font-plus-jakarta-sans ${isMobile ? "pt-12  text-7xl text-center" : "text-17xl text-left"} text-white leading-none `}
              >
                {title}
              </h1>
            </div>
            
          
            <div className={`${isMobile ? "w-full mt-2 justify-center items-center" : "w-2/5 mt-12 ml-[50rem]"}  fade-in  flex flex-col `}>
              <p
                className={`font-plus-jakarta-sans ${isMobile ? "text-xl text-center" : "text-3xl text-left"}  p-8 text-white `}
              >
                {description}
              </p>
              <Button
                className={`rounded-full w-48 p-7 text-lg ${isMobile ? "" : "ml-8"} font-plus-jakarta-sans font-semibold text-black bg-gradient-to-r from-[#95A4FC] to-[#B1FA63]`}
                onClick={() => {
                  window.open(
                    "https://calendly.com/ish_agrawal/30min",
                    "_blank",
                  );
                }}
              >
                Book A Demo
              </Button>
            </div>
          </div>
    
    
    
    
      <h1 className={`font-bold font-plus-jakarta-sans helpar-gradient-text text-center ${isMobile ? "text-5xl mt-24" : "text-8xl mt-60"}  mb-12  fade-in`}>
        Top Features
      </h1>
      <div className={`w-full  items-center justify-center flex flex-wrap gap-8 ${isMobile ? "pl-4 pr-4" : ""}`}>
        {cards.map((card, index) => {
          return (
            <SolutionCard key={index} className={card.className} {...card} />
          );
        })}
      </div>
    </div>
  );
};

export default Solution;
