import React from "react";
import TopNavigation from "../components/TopNavigation";
import { isMobileDevice } from "../utils";

const Terms: React.FC = () => {
  const isMobile = isMobileDevice();
  return (
    <div className="viewarea bg-black flex flex-col items-center overflow-y-auto">
      <TopNavigation />
      <div
        className={`p-8 mb-36 ${
          isMobile ? "w-full" : "w-1/2"
        } flex flex-col gap-y-4`}
      >
        <h1
          className={`font-plus-jakarta-sans text-white font-bold text-${
            isMobile ? "5" : "10"
          }xl`}
        >
          Terms of Usage
        </h1>
        <p className="font-plus-jakarta-sans text-white ">
          Last Updated: 03/15/2024
        </p>

        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          1. Introduction
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          Welcome to HelpAR (the “Platform”), provided by HelpAR Inc. in the
          United States and globally (referred to as “HelpAR”, “we”, “us”, or
          “our”).
        </p>
        <p className="font-plus-jakarta-sans text-white">
          These Terms of Service (“Terms”) govern your access to and use of the
          Platform, including our websites, services, applications, products,
          and content (collectively, the “Services”). The Services are intended
          for private, non-commercial use. By using the Services, you agree to
          these Terms and our Privacy Policy, incorporated herein by reference.
        </p>

        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          2. Acceptance of Terms
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          By accessing or using our Services, you confirm that you can form a
          binding contract with HelpAR, that you accept these Terms, and that
          you agree to comply with them, including our Privacy Policy. If you
          are using the Services on behalf of a business or entity, you
          represent that you have the authority to bind the entity to these
          Terms and have ensured the entity's compliance with GDPR and other
          relevant laws.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          3. Changes to the Terms
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          We may modify these Terms from time to time to reflect changes to our
          Services, legal, regulatory, or operational requirements, or for other
          reasons deemed necessary to enhance privacy and compliance standards.
          We will notify users of any material changes through our Services or
          via email and update the "Last Updated" date. Your continued use of
          the Services after changes become effective means you accept those
          changes. Please review these Terms regularly to ensure your awareness
          of any changes.
        </p>

        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          4. Your Account
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          When creating and maintaining your account, you agree to provide
          accurate and current information and to promptly update such
          information in accordance with GDPR requirements for data accuracy.
          You are responsible for safeguarding your account and for all
          activities that occur under your account. We reserve the right to
          disable your account in case of any breach of these Terms or if your
          account activity poses a risk of harm.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          5. Use of Our Services
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          You agree to use our Services only for lawful purposes and in
          accordance with these Terms. Prohibited activities include, but are
          not limited to, making unauthorized copies, distributing viruses,
          sending spam, impersonating others, and using the Services for
          commercial purposes without our consent.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          6. Intellectual Property Rights
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          The content and materials provided through our Services are the
          property of HelpAR or its licensors. You are granted a limited,
          non-exclusive license to access and use the Services for personal,
          non-commercial purposes as per these Terms.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          7. Indemnification
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          {" "}
          You agree to indemnify and hold harmless HelpAR and its affiliates
          from any claims, damages, or expenses arising from your use of the
          Services or violation of these Terms.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          8. Disclaimers and Limitation of Liability
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          The Services are provided "as is" without warranties of any kind.
          HelpAR is not liable for indirect or incidental damages related to
          your use of the Services. Some jurisdictions do not allow the
          exclusion of certain warranties or limitations of liability, so the
          above exclusions may not apply to you.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          9. General Terms
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          These Terms constitute the entire agreement between you and HelpAR
          regarding the Services. If any provision of these Terms is deemed
          invalid or unenforceable, the remaining provisions will remain in
          effect.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          10. Dispute Resolution
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          Any disputes arising from these Terms will be resolved through binding
          arbitration or in small claims court, rather than in court, except
          that you may assert claims in small claims court if your claims
          qualify.
        </p>
        <p className="font-plus-jakarta-sans text-white">
          Please include a clause that encourages contacting us directly to
          resolve any issues before resorting to arbitration, to comply with
          GDPR's emphasis on dispute resolution and consumer rights protection.
        </p>
        <h1 className="font-plus-jakarta-sans text-white text-4xl font-bold">
          11. Contact Us
        </h1>
        <p className="font-plus-jakarta-sans text-white">
          For questions about these Terms, please contact us at
          webmaster@helpar.app.
        </p>
      </div>
    </div>
  );
};

export default Terms;
