import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./views/Index";
import Integrations from "./views/Integrations";
import Modules from "./views/Modules";
import About from "./views/About";
import Pricing from "./views/Pricing";
import Solutions from "./views/Solutions";
import Team from "./views/Team";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";
import FounderMade from "./views/FounderMade";

import "./styles/route.css";

interface AppRouterProps {
  children?: React.ReactNode;
}

const AppRouter = ({ children }: AppRouterProps): React.JSX.Element => {
  return (
    <Router>
      {children}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/solutions/:solution" element={<Solutions />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/founder-made" element={<FounderMade />} />
        <Route path="/foundermade" element={<FounderMade />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
